import { NgModule } from "@angular/core";
import { BackPageDirective } from "./back-page.directive";
import { CallToDirective } from "./call-to.directive";
import { CopyValueDirective } from "./copy-value.directive";
import { ScreenDirective } from "./device.directive";
import { EmailToDirective } from "./email-to.directive";
import { FunctionPermissionDirective } from "./function-permission.directive";
import { TypeSafeMatCellDefDirective } from "./type-safe-mat-cell-def.directive";
import { WhatsAppToDirective } from "./ws-to.directive";

@NgModule({
  declarations: [
    EmailToDirective,
    CallToDirective,
    WhatsAppToDirective,
    ScreenDirective,
    BackPageDirective,
    CopyValueDirective,
    FunctionPermissionDirective,
    TypeSafeMatCellDefDirective
  ],
  exports: [
    EmailToDirective,
    CallToDirective,
    WhatsAppToDirective,
    ScreenDirective,
    BackPageDirective,
    CopyValueDirective,
    FunctionPermissionDirective,
    TypeSafeMatCellDefDirective
  ]
})
export class CustomDirectiveModule {}
