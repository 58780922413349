import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RiderAccidentDetailsComponent } from "./rider-accident-details/rider-accident-details.component";
import { RiderAccidentsComponent } from "./rider-accidents/rider-accidents.component";

const routes: Routes = [
  {
    path: ":partner",
    component: RiderAccidentsComponent,
  },
  {
    path: ":partner/rider-accident-details",
    component: RiderAccidentDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RiderAccidentRoutingModule {}
