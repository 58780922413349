import { Clipboard } from "@angular/cdk/clipboard";
import { ElementRef } from "@angular/core";
import { Input } from "@angular/core";
import { HostListener } from "@angular/core";
import { Directive } from "@angular/core";
import { AlertService } from "../service/alert.service";

@Directive({
	selector: "[copyValue]",
})
export class CopyValueDirective {
	@Input() copyValue;

	constructor(
		private clipboard: Clipboard,
		private alertService: AlertService,
		private elRef: ElementRef,
	) {
		this.elRef.nativeElement.style.cursor =
			"url('../../../../assets/icons/cursor_copy.png'),pointer";
	}

	@HostListener("click", ["$event"]) onClick() {
		if (this.copyValue && this.copyValue != "-") {
			this.clipboard.copy(this.copyValue);
			this.alertService.openSnackBar(
				`'${this.copyValue}' copied to your clipboard`,
			);
		}
	}
}
