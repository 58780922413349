import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { mapPartnerCode } from "src/app/rider-accident/_utils/rider-accident.utils";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MotorInsuredPolicyDataService {
  private basePath: string = `${environment.url}/api/Motor/MotorInsuredPolicy/`;

  constructor(private http: HttpClient) {}

  getListing(
    id: string,
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    status: string,
    startDate?: Date,
    endDate?: Date,
    partner?: string,
  ) {
    return this.http.post<{
      count: number;
      data: any[];
      before6Count: number;
      after6Count: number;
    }>(`${this.basePath}GetListing/${id}`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      status,
      startDate,
      endDate,
      partner: mapPartnerCode(partner),
    });
  }

  getDetailById(id: string) {
    return this.http.get(`${this.basePath}GetDetailById/${id}`);
  }

  getDocuments(id: string) {
    return this.http.get(`${this.basePath}GetDocuments/${id}`);
  }

  getPolicyClaims(id: string, text: string = "") {
    return this.http.post(
      `${this.basePath}GetPolicyClaims/${id}?text=${text}`,
      {},
    );
  }

  updateStatusPolicy(id: string) {
    return this.http.get(`${this.basePath}UpdateStatusPolicy/${id}`);
  }

  getClaimDeclarationHistory(
    insuredId: string,
    startDate: Date,
    endDate: Date,
  ) {
    return this.http.post(
      `${this.basePath}GetDeclarationHistoryByInsuredId/${insuredId}`,
      { startDate: startDate, endDate: endDate, sortDirection: "asc" },
    );
  }
}
