import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CommonSelectionInterface } from "src/app/share/interface/common.interface";
import { DirectLendingListingInterface } from "../interfaces/direct-lending.interface";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class DirectLendingService {
  private basePath: string = `${environment.url}/api/DirectLendingPolicy/`;
	constructor(private http: HttpClient) {}

  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    startDate: Date,
    endDate: Date,
    policyStatusList?: string[]
  ): Observable<{ count: number; data: DirectLendingListingInterface[] }> {
    return this.http.post<{
      count: number;
      data: DirectLendingListingInterface[];
    }>(`${this.basePath}GetListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      startDate,
      endDate,
      policyStatusList
    });
  }

  getPolicyListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    startDate: Date,
    endDate: Date,
    policyStatusList?: string[]
  ): Observable<Blob> {
    return this.http.post(
      `${this.basePath}GetPolicyListingExcel`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        startDate,
        endDate,
        policyStatusList
      },
      {
        responseType: "blob"
      }
    );
  }

  uploadExcel(file: any) {
    return this.http.post(`${this.basePath}UploadPolicyExcel`, file, {
      responseType: "blob",
    });
  }
}
