import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectLendingComponent } from './direct-lending/direct-lending.component';

const routes: Routes = [
  {
		path: "main",
		component: DirectLendingComponent,
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DirectLendingRoutingModule { }
