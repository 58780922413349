import { AlertService } from "src/app/share/service/alert.service";
import { finalize, catchError } from "rxjs/operators";
import { of } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Component, Inject } from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { FileFunction } from "src/app/share/function/file.function";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DirectLendingService } from "../../services/direct-lending.service";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  formGroup: FormGroup;
  errorMessage: string = "";
  partnerGroupData: any;
  uploadedFile: any;
  partnerGroupList: any[] = [];

  constructor(
    private directLendingService: DirectLendingService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<UploadFileComponent>,
    private ngxSpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data: {
      type: string;
    },
  ) {
    this.formGroup = this.fb.group({
      uploadedFile: [null, Validators.required],
    });
  }

  deleteFile() {
    this.formGroup.get("uploadedFile").setValue(null);
  }
  fileChangeEvent(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!allowedTypes.includes(file.type)) {
        this.alertService.openSnackBar("Only Excel files are allowed.");
        return;
      }

      this.formGroup.get("uploadedFile").setValue(file);
    }
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.ngxSpinnerService.show();
      const formData = new FormData();
      formData.append("File", this.formGroup.value.uploadedFile);

      this.directLendingService
        .uploadExcel(formData)
        .pipe(
          finalize(() => {
            this.ngxSpinnerService.hide();
            this.formGroup.reset();
            this.bottomSheetRef.dismiss({ success: true });
          }),
          catchError((error) => {
            this.ngxSpinnerService.hide();
            console.error("Error uploading file:", error);
            this.alertService.openSnackBar("Error uploading file.");
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response && response.size !== 0) {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob(binaryData, { type: dataType }),
            );
            downloadLink.setAttribute("download", "error");
            document.body.appendChild(downloadLink);
            downloadLink.click();
            this.ngxSpinnerService.hide();
          } else {
            this.ngxSpinnerService.hide();
            this.alertService.openSnackBar("File uploaded successfully.");
          }
        });
    }
  }
}
