import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, throwError } from 'rxjs';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DeclarationHistoryListComponent } from 'src/app/rider-accident/components/declaration-history-list/declaration-history-list.component';
import { PolicyCancellationUploadComponent } from 'src/app/share/dialog/policy-cancellation-upload/policy-cancellation-upload.component';
import { AlertService } from 'src/app/share/service/alert.service';
import { UploadFileComponent } from '../components/upload-file/upload-file.component';
import { DirectLendingService } from '../services/direct-lending.service';
import { DirectLendingListingInterface } from '../interfaces/direct-lending.interface';
import { CommonSelectionInterface } from 'src/app/share/interface/common.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonalProtectionDataService } from 'src/app/share/data-service/personal-perotection/personal-protection.data.service';

@Component({
  selector: 'app-direct-lending',
  templateUrl: './direct-lending.component.html',
  styleUrls: ['./direct-lending.component.scss']
})
export class DirectLendingComponent implements OnInit {

  dataSource: DirectLendingListingInterface[] = [];

  displayedColumns: string[] = [
    "date",
    "cert_number",
    "loan_id",
    "name",
    "dob",
    "email_address",
    "date_issued",
    "coverage_period",
    "occupation",
    "plan_type",
    "nominee_name",
    "nric",
    "relationship",
    "share",
    "status"
  ];

  selectedStatus: string = "All";

  startDate: Date;
  endDate: Date;

  filterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";
  lastFilterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";

  status: string = null;
  length = 0;
  limit = 50;
  minFilterEndDate: Date;
  offset = 1;
  policyStatusList: CommonSelectionInterface[] = [];
  searchText: string = "";
  searchChange: Subject<void> = new Subject<void>();
  unsubscribe$ = new Subject<void>();
  selectedPolicyStatusList: string[] = [];
  filterPolicyStatusList: CommonSelectionInterface[] = [];

  sortIndex: number = 0;
  sortDirection = "";

  list: any[] = [];
  isLoading: boolean = true;
  selected: any;

  @ViewChild("paginator") paginator;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private personalProtectionDataService: PersonalProtectionDataService,
    private bottomSheet: MatBottomSheet,
    private directLendingService: DirectLendingService,
    private router: Router,   
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getListSelection();
    this.searchChange
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.filter();
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getListSelection(): void {
    this.policyStatusList = this.personalProtectionDataService.getPolicyStatusList();
    this.filterPolicyStatusSelection();
    this.toggleSelectAllPolicyStatus(true, false);
    this.resumeFilterQueryParams();
    this.getData();
  }

  private resumeFilterQueryParams(): void {
    const { by, offset, sortIndex, sortDirection, filter, startDate, endDate } =
      this.route.snapshot.queryParams;

    if (by) {
      this.filterSelected = by;

      if (by === "custom") {
        const validStartDate =
          new Date(startDate).toString() !== "Invalid Date";
        const validEndDate = new Date(endDate).toString() !== "Invalid Date";
        if (!validStartDate || !validEndDate) {
          return;
        }
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
      } else {
        this.startDateClose();
      }
    }

    if (offset) {
      this.offset = offset || 1;
      this.paginator.pageIndex = this.offset - 1;
    }

    if (sortIndex && sortIndex !== -1) {
      this.sortIndex = sortIndex;
    }

    if (sortDirection) {
      this.sortDirection = sortDirection;
    }

    this.searchText = filter;
  }

  private getData(): void {
    this.isLoading = true;
    this.dataSource = [];

    this.directLendingService
      .getListing(
        this.limit,
        this.offset,
        this.searchText,
        this.sortIndex,
        this.sortDirection,
        this.startDate,
        this.endDate,
        this.selectedPolicyStatusList
      )
      .subscribe({
        next: (x) => {
          this.dataSource = x.data;
          this.length = x.count;
          this.applyFilterToUrl();
        },
        error: (err) => this.alertService.openSnackBar(err.error)
      })
      .add(() => (this.isLoading = false));
  }

  private applyFilterToUrl(): void {
    if (
      this.searchText ||
      (this.sortIndex !== -1 && this.sortDirection) ||
      this.filterSelected !== "all"
    ) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          filter: this.searchText?.length > 0 ? this.searchText : null,
          sortIndex: this.sortIndex !== -1 ? this.sortIndex : null,
          sortDirection: this.sortDirection !== "" ? this.sortDirection : null,
          by: this.filterSelected !== "all" ? this.filterSelected : null,
          startDate:
            this.filterSelected === "custom" && this.startDate
              ? this.startDate.toISOString()
              : null,
          endDate:
            this.filterSelected === "custom" && this.endDate
              ? this.endDate.toISOString()
              : null,
          offset: this.offset !== 1 ? this.offset : null
        },
        queryParamsHandling: "merge",
        replaceUrl: true
      });
      return;
    }

    this.router.navigate([]);
  }

  uploadFile() {
    const bottomRef = this.bottomSheet.open(UploadFileComponent, {
      backdropClass: "sidenav-container",
      data: {
        type: "dealer",
      },
      disableClose: true,
    });
    bottomRef.afterDismissed().subscribe((x) => {
      if (x) {
        this.getData();
      }
    });
  }

  onExportData(): void {
    this.ngxSpinnerService.show();

    this.directLendingService
      .getPolicyListingExcel(
        this.limit,
        this.offset,
        this.searchText,
        this.sortIndex,
        this.sortDirection,
        this.startDate,
        this.endDate,
        this.selectedPolicyStatusList
      )
      .subscribe({
        next: (x: any) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([x], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          downloadLink.setAttribute(
            "download",
            "PersonaProtectionDirectLending.xlsx"
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => this.alertService.openSnackBar("Error exporting data")
      })
      .add(() => this.ngxSpinnerService.hide());
  }

  isSelectedAllPolicyStatus(): boolean {
    return this.selectedPolicyStatusList.length == this.policyStatusList.length;
  }

  isIndeterminatePolicyStatus(): boolean {
    return this.isSelectedAllPolicyStatus()
      ? false
      : this.selectedPolicyStatusList.length > 0;
  }

  toggleSelectAllPolicyStatus(val: boolean, hasFilter = true): void {
    this.selectedPolicyStatusList = [];
    if (!val) {
      this.dataSource = [];
      return;
    }

    this.selectedPolicyStatusList = this.policyStatusList.map((y) => y.id);
    if (hasFilter) {
      this.filter();
    }
  }

  getPolicyStatusDisplay(): string {
    if (this.selectedPolicyStatusList.length == 1) {
      return this.policyStatusList.find(
        (x) => x.id == this.selectedPolicyStatusList[0]
      ).name;
    }

    if (this.isSelectedAllPolicyStatus()) {
      return "All policy status";
    }

    return `${this.selectedPolicyStatusList.length} policy status selected`;
  }

  filterPolicyStatusSelection(searchValue = ""): void {
    if (!searchValue) {
      this.filterPolicyStatusList = Object.assign([], this.policyStatusList);
      return;
    }

    let txt = searchValue.toLowerCase().trim();
    this.filterPolicyStatusList = this.policyStatusList.filter((x) =>
      x.name.toLowerCase().includes(txt)
    );
  }

  private setDefaultTimeRange() {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 999);
  }

  resetStatus() {
    this.selectedStatus = "All";
    this.status = null;
    this.filter();
  }

  filterStatus() {
    this.status = this.selectedStatus;
    this.getData();
  }

  //#region Date filter
  setTodayDate(hasFilter: boolean = true) {
    this.startDate = new Date();
    this.endDate = new Date();
    this.setDefaultTimeRange();
    if (hasFilter) {
      this.filter();
    }
  }

  setCurrentWeekDate() {
    this.startDate = moment().startOf("week").toDate();
    this.endDate = moment().endOf("week").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentYearDate() {
    this.startDate = moment().startOf("year").toDate();
    this.endDate = moment().endOf("year").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  resetDate() {
    this.startDate = null;
    this.endDate = null;
    this.filter();
  }

  onStartDateSelected(e) {
    this.startDate = e.target.value;
  }

  onEndDateSelected(e) {
    this.endDate = new Date(e.target.value.setHours(23, 59, 59, 999));
  }

  onDateRangePickerClosed() {
    if (this.startDate && this.endDate) {
      this.filter();
    } else if (this.startDate == null) {
      this.startDateClose();
    } else if (this.endDate == null) {
      this.endDateClose();
    }
  }

  startDateOpen() {
    this.startDate = null;
    this.endDate = null;
  }

  startDateClose() {
    if (this.startDate == null) {
      this.filterSelected = this.lastFilterSelected;
      switch (this.filterSelected) {
        case "today":
          this.setTodayDate();
          break;
        case "week":
          this.setCurrentWeekDate();
          break;
        case "month":
          this.setCurrentMonthDate();
          break;
        case "year":
          this.setCurrentYearDate();
          break;
        case "all":
          this.resetDate();
          break;
      }
    }
  }

  endDateClose() {
    if (this.endDate == null) {
      this.endDate = this.startDate;
    }
    this.filter();
  }

  resetAllFilters() {
    this.searchText = null;
    this.selectedStatus = "All";
    this.status = null;
    this.startDate = null;
    this.endDate = null;
    this.filterSelected = "all";
    this.lastFilterSelected = this.filterSelected;
  }

  resetDateFilter() {
    event.stopImmediatePropagation();
    this.resetDate();
    this.filterSelected = "all";
    this.lastFilterSelected = this.filterSelected;
  }
  //#endregion

  resetTextFilter() {
    event.stopImmediatePropagation();
    this.searchText = null;
    this.filter();
  }

  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active,
      );
    }
    if (this.dataSource.length == 0) return;

    this.getData();
  }
  pageChange(e: PageEvent) {
    this.offset = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.getData();
  }
  filter() {
    this.offset = 1;
    this.getData();
  }

  searchChanged() {
    this.searchChange.next();
  }

}
