<mat-toolbar class="page-header">
    <div class="menu-container">
      <div class="title-container">
        <div class="pg-title">
          Direct Lending
          <!-- <div class="label-f">Rider Protection</div> -->
        </div>
      </div>
  
      <div class="filter-container">
        <mat-form-field appearance="outline" floatLabel="never" class="pg-filter">
          <input
            matInput
            placeholder="Search here..."
            autocomplete="off"
            [(ngModel)]="searchText"
            (ngModelChange)="searchChanged()"
          />
          <button
            mat-icon-button
            matSuffix
            class="clear-field-btn"
            (click)="resetTextFilter()"
            *ngIf="searchText"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pg-filter" floatLabel="never">
          <mat-select
            (ngModelChange)="selectedPolicyStatusList = $event; filter()"
            [ngModel]="selectedPolicyStatusList"
            multiple
          >
            <mat-select-trigger>
              {{ getPolicyStatusDisplay() }}
            </mat-select-trigger>
            <mat-option>
              <ngx-mat-select-search
                (ngModelChange)="filterPolicyStatusSelection($event)"
                (toggleAll)="toggleSelectAllPolicyStatus($event)"
                [ngModelOptions]="{ standalone: true }"
                [searching]="!policyStatusList.length"
                [showToggleAllCheckbox]="true"
                [toggleAllCheckboxChecked]="isSelectedAllPolicyStatus()"
                [toggleAllCheckboxIndeterminate]="isIndeterminatePolicyStatus()"
                ngDefaultControl
                ngModel
                noEntriesFoundLabel="No matching status found"
                placeholderLabel="Search policy status..."
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let item of filterPolicyStatusList"
              [value]="item.id"
            >
              {{ item.name }}
            </mat-option>
          </mat-select>
          <button
            (click)="toggleSelectAllPolicyStatus(true)"
            *ngIf="!isSelectedAllPolicyStatus()"
            class="clear-field-btn"
            mat-icon-button
            matSuffix
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          floatLabel="never"
          class="pg-filter"
          (click)="lastFilterSelected = filterSelected"
        >
          <mat-select [(ngModel)]="filterSelected">
            <mat-select-trigger>
              <ng-container [ngSwitch]="filterSelected">
                <span *ngSwitchCase="'today'">Today</span>
                <span *ngSwitchCase="'week'">This Week</span>
                <span *ngSwitchCase="'month'">This Month</span>
                <span *ngSwitchCase="'year'">This Year</span>
                <span *ngSwitchCase="'all'">All Time</span>
                <span *ngSwitchDefault>
                  <span *ngIf="endDate">
                    {{ startDate | date : "dd/MM/yy" }}
                    - {{ endDate | date : "dd/MM/yy" }}
                  </span>
                </span>
              </ng-container>
            </mat-select-trigger>
            <mat-option value="all" (click)="resetDate()">All Time</mat-option>
            <mat-option value="year" (click)="setCurrentYearDate()"
              >This Year</mat-option
            >
            <mat-option value="month" (click)="setCurrentMonthDate()"
              >This Month</mat-option
            >
            <mat-option value="week" (click)="setCurrentWeekDate()"
              >This Week</mat-option
            >
            <mat-option value="today" (click)="setTodayDate()">Today</mat-option>
            <mat-option value="custom" (click)="picker.open()"
              >Custom Range</mat-option
            >
            <!-- NEW DATE PICKER UI  -->
            <mat-date-range-input style="display: none" [rangePicker]="picker">
              <input
                matStartDate
                [(ngModel)]="startDate"
                (dateChange)="onStartDateSelected($event)"
                placeholder="Start date"
              />
              <input
                matEndDate
                [(ngModel)]="endDate"
                (dateChange)="onEndDateSelected($event)"
                placeholder="End date"
              />
            </mat-date-range-input>
            <mat-date-range-picker
              #picker
              (opened)="startDateOpen()"
              (closed)="onDateRangePickerClosed()"
            >
            </mat-date-range-picker>
          </mat-select>
          <button
            mat-icon-button
            matSuffix
            class="clear-field-btn"
            (click)="resetDateFilter()"
            *ngIf="filterSelected !== 'all'"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </mat-toolbar>
  
  <div class="content-container">
    <div class="flex justify-between">
      <button
        appCheckFunctionPermission
        mat-stroked-button 
        color="primary"
        (click)="uploadFile()"
      >
        Upload Confirmation of Cover Listing
      </button>
      <button mat-stroked-button color="primary" (click)="onExportData()">
        Export Data
      </button>
    </div>
  
    <mat-card class="card-table">
      <table
      (matSortChange)="sortChange($event)"
      [dataSource]="dataSource"
      mat-table
      matSort
      >
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date Created
          </th>
          <td mat-cell *matCellDef="let element; dataSource: dataSource">
            {{ element.createdDate | date : "dd MMM yyyy" }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="cert_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Certificate No.</th>
          <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.certificateNo }}</td>
        </ng-container>
  
        <ng-container matColumnDef="loan_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Loan ID</th>
          <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.loanId }}</td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="dob">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Birth</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.dateOfBirth | date : "dd MMM yyyy" }}</td>
        </ng-container>

        <ng-container matColumnDef="email_address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.emailAddress }}</td>
        </ng-container>

        <ng-container matColumnDef="date_issued">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Issued</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.issuedDate | date : "dd MMM yyyy" }}</td>
        </ng-container>

        <ng-container matColumnDef="coverage_period">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Coverage Period</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.coveragePeriodStartDate | date : "dd MMM yyyy"  }} - {{ element.coveragePeriodEndDate | date : "dd MMM yyyy"  }}</td>
        </ng-container>

        <ng-container matColumnDef="occupation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Occupation</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.occupation }}</td>
        </ng-container>

        <ng-container matColumnDef="plan_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Type (Month)</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.planTypeMonth }}</td>
        </ng-container>

        <ng-container matColumnDef="nominee_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nominee Name</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.nomineeName }}</td>
        </ng-container>

        <ng-container matColumnDef="nric">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NRIC/Passport No.</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.nomineeIdentityNo }}</td>
        </ng-container>

        <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Relationship</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.relationship }}</td>
        </ng-container>

        <ng-container matColumnDef="share">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Share</th>
            <td mat-cell *matCellDef="let element; dataSource: dataSource">{{ element.share }}</td>
        </ng-container>
  
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
  
          <td mat-cell *matCellDef="let element; dataSource: dataSource" [ngSwitch]="element.status">
            <span class="cover-label green" *ngSwitchCase="'Active'">
              Active
            </span>
            <span class="cover-label red" *ngSwitchCase="'Deactivated'">
              Deactivated
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
          </td>
        </ng-container>
  
        <!-- Anchor Column -->
        <!-- <ng-container matColumnDef="anchor">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              [href]="
                '/rideraccident/' +
                partner +
                '/rider-accident-details?id=' +
                element.id
              "
            ></a>
          </mat-cell>
        </ng-container> -->
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <tr *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <app-empty-record
              *ngIf="!isLoading && list.length === 0"
              [disableSubTitle]="true"
            ></app-empty-record>
            <app-skeleton-loader *ngIf="isLoading"> </app-skeleton-loader>
          </td>
        </tr>
      </table>
      
      <mat-divider></mat-divider>
  
      <mat-paginator
        #paginator
        [pageSizeOptions]="[50, 100, 150]"
        [pageSize]="limit"
        [length]="length"
        (page)="pageChange($event)"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card>
  </div>
  <!-- <mat-menu #menu="matMenu">
    <div
      appCheckFunctionPermission
    >
      <button
        appCheckFunctionPermission
        mat-menu-item
        (click)="viewDeclarationHistory(selected)"
      >
        View declaration history
      </button>
    </div>
  </mat-menu> -->
  
