import { Injectable } from "@angular/core";
import { CommonSelectionInterface } from "../../interface/common.interface";

@Injectable({
	providedIn: "root",
})
export class PersonalProtectionDataService {

	constructor() {}

	getPolicyStatusList(): CommonSelectionInterface[] {
		return [
			{ id: "Active", name: "Active" },
			{ id: "Deactivated", name: "Deactivated" }
		];
	}
}
