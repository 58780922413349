<div>
  <div mat-dialog-title>Rider Declaration History</div>
  <mat-divider></mat-divider>
  <div>
    <div mat-dialog-content>
      <div class="m-b-16">
        <div>
          <span>IC No:</span>
          <span class="m-l-20 fs-16 fw-md">{{ riderData.identityNo }}</span>
        </div>
        <div>
          <span>Name:</span
          ><span class="m-l-20 fs-16 fw-md">{{ riderData.name }}</span>
        </div>
      </div>

      <mat-toolbar class="filter-header">
        <div class="menu-container">
          <span fxFlex></span>

          <div style="display: none">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [matDatepicker]="datePicker"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="customDateChange($event)"
                ngModel
                ngDefaultControl
              />
              <mat-hint>MM/DD/yyyy</mat-hint>
              <mat-datepicker #datePicker [touchUi]="true"></mat-datepicker>
            </mat-form-field>
          </div>

          <mat-form-field
            appearance="outline"
            floatLabel="never"
            class="pg-filter"
            (click)="lastFilterSelected = filterSelected"
          >
            <mat-select [(ngModel)]="filterSelected">
              <mat-select-trigger>
                <ng-container [ngSwitch]="filterSelected">
                  <span *ngSwitchCase="'month'">This Month</span>
                  <span *ngSwitchCase="'6mths'">Past 6 Months</span>
                  <span *ngSwitchDefault>
                    <span>
                      {{ startDate | date : "dd MMM yyyy" }}
                    </span>
                  </span>
                </ng-container>
              </mat-select-trigger>
              <mat-option value="month" (click)="setCurrentMonthDate()"
                >This Month</mat-option
              >
              <mat-option value="6mths" (click)="setPast6MonthsDate()"
                >Past 6 Months</mat-option
              >
              <mat-option value="custom" (click)="datePicker.open()"
                >Custom Date</mat-option
              >
            </mat-select>
            <button
              mat-icon-button
              matSuffix
              class="clear-field-btn"
              (click)="resetDateFilter()"
              *ngIf="filterSelected !== 'month'"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </mat-toolbar>

      <div class="m-b-16">
        <table mat-table [dataSource]="list">
          <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef>Riding period</th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDate | date : "dd MMM yyyy" }} -
              {{ element.endDate | date : "dd MMM yyyy" }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr *matNoDataRow>
            <td class="mat-cell" colspan="9999">
              <app-empty-record
                *ngIf="!isLoading && list.length === 0"
                [disableSubTitle]="true"
              ></app-empty-record>
              <app-skeleton-loader *ngIf="isLoading"> </app-skeleton-loader>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Close</button>
    </div>
  </div>
</div>
