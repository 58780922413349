import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { mapPartnerCode } from "src/app/rider-accident/_utils/rider-accident.utils";
import { environment } from "src/environments/environment";
import { RiderAccidentPartners } from "../../../rider-accident/_interfaces/rider-accidents.interface";

@Injectable({
  providedIn: "root",
})
export class MotorPartnerDataService {
  private basePath: string = `${environment.url}/api/Motor/MotorPartner/`;

  constructor(private http: HttpClient) {}

  getRiderAccidentPartners(partner?: string) {
    return this.http.get<RiderAccidentPartners[]>(
      `${this.basePath}GetRiderAccidentPartners?partner=${mapPartnerCode(
        partner,
      )}`,
    );
  }
}
