import {
	Directive,
	Input,
	ElementRef,
	Renderer2,
	OnInit,
	HostListener,
	AfterViewInit,
} from "@angular/core";
import { FunctionPermissionsService } from "../service/function-permission.service";
import { AlertService } from "src/app/share/service/alert.service";

@Directive({
	selector: "[appCheckFunctionPermission]",
})
export class FunctionPermissionDirective implements AfterViewInit {
	@Input() functionCode: string | string[]; // Function code as an input

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private permissionsService: FunctionPermissionsService,
		private alertService: AlertService,
	) {}

	ngAfterViewInit() {
		if (!this.functionCode) return;
		const hasPermission = this.checkPermission();
		if (!hasPermission) {
			this.renderer.setProperty(this.el.nativeElement, "disabled", true);
		}
	}

	@HostListener("mouseenter") onMouseEnter() {
        if (!this.functionCode) return;
		const hasPermission = this.checkPermission();
		if (!hasPermission) {
			this.alertService.openSnackBar(
				"You do not have permission to perform this action",
			);
		}
	}

	private checkPermission(): boolean {
		return this.permissionsService.hasPermission(this.functionCode);
	}
}
