<div class="drawer-content">
  <mat-nav-list>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let panel of filterLevelOneAccess()"
        [expanded]="tab === panel.tabUrl"
      >
        <mat-expansion-panel-header class="disable_ripple">
          <mat-panel-title>
            <mat-icon
              mat-list-icon
              [ngClass]="panel.customClass ? panel.customClass : ''"
              >{{ panel.tabIcon }}</mat-icon
            >
            <span class="drawer-style">{{ panel.tabTitle }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list-item
          *ngFor="let item of filterLevelTwoAccess(panel.levelTwo)"
          routerLinkActive="active"
          routerLink="{{ item.url }}"
          appAppDrawer
        >
          <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
          <div class="title" mat-line>{{ item.title }}</div>
        </mat-list-item>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-nav-list>
</div>
