import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { mapPartnerCode } from "src/app/rider-accident/_utils/rider-accident.utils";
import { Observable } from "rxjs";
import { RiderAccidentPartners } from "../../../rider-accident/_interfaces/rider-accidents.interface";

@Injectable({
  providedIn: "root",
})
export class MotorClaimDataService {
  private basePath: string = `${environment.url}/api/Motor/MotorClaim/`;
  private masterDataPath: string = `${environment.url}/api/MasterData/`;

  constructor(private http: HttpClient) {}

  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    status: string[],
    startDate?: Date,
    endDate?: Date,
    partner?: string,
  ) {
    return this.http.post<{
      count: number;
      data: any[];
      before6Count: number;
      after6Count: number;
    }>(`${this.basePath}GetListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      status,
      startDate,
      endDate,
      partner: mapPartnerCode(partner),
    });
  }

  getDetailById(id: string) {
    return this.http.get(`${this.basePath}GetDetailById/${id}`);
  }

  // Claim total
  getClaimValueById(id: string) {
    return this.http.get(`${this.basePath}GetClaimValueById/${id}`);
  }
  updateClaimValue(value: any) {
    return this.http.post(`${this.basePath}UpdateClaimValue`, {
      claimValueList: value,
    });
  }

  // Claim status
  getClaimLogById(id: string) {
    return this.http.get(`${this.basePath}GetClaimLogById/${id}`);
  }
  updateClaimLog(id: string, value: any) {
    return this.http.post(`${this.basePath}UpdateClaimLog/${id}`, value);
  }

  // Partner status
  getClaimPartnerLogById(id: string) {
    return this.http.get(`${this.basePath}GetClaimPartnerLogById/${id}`);
  }

  updateClaimPartnerLogById(id: string, value: any) {
    return this.http.post(`${this.basePath}UpdateClaimPartnerLog/${id}`, value);
  }

  updateEventDate(id: string, eventDate: Date) {
    return this.http.post(`${this.basePath}UpdateEventDate/${id}`, {
      eventDate: eventDate,
    });
  }

  downloadAcceptedAttachment(id: string, idList: string[]) {
    return this.http.post(
      `${this.basePath}DownloadAcceptedAttachment/${id}`,
      {
        idList: idList,
      },
      { responseType: "blob" },
    );
  }
  uploadInsuredExcel(
    partnerDataList: RiderAccidentPartners[],
    file: any,
    startDate: Date,
    endDate: Date,
    partner?: string,
  ) {
    let fd = new FormData();
    fd.append("File", file);
    fd.append("StartDate", startDate.toUTCString());
    fd.append("EndDate", endDate.toUTCString());
    fd.append("Partner", mapPartnerCode(partner));


    if (mapPartnerCode(partner) === "SX") {
      partnerDataList.forEach((partner, index) => {
        fd.append(`SPXInsurances[${index}].insuranceId`, partner.insuranceId);
        fd.append(`SPXInsurances[${index}].insuranceNo`, partner.insuranceNo)
      });
    } else {
      fd.append("InsuranceId", partnerDataList[0].insuranceId);
    }

    return this.http.post(`${this.basePath}UploadInsuredExcel`, fd, {
      responseType: "blob",
    });
  }

  getClaimDocument(id: string, type: string) {
    return this.http.post(`${this.basePath}GetClaimDocument/${id}`, type);
  }

  getClaimDocumentStatusSelection() {
    return this.http.get<string[]>(
      `${this.masterDataPath}GetClaimDocumentStatusSelection`,
    );
  }

  downloadExcelTemplate(): Observable<any> {
    return this.http.get(`${this.basePath}DownloadInsuredExcelTemplate`, {
      responseType: "blob",
    });
  }

  getClaimStatusSelection() {
    return this.http.get<string[]>(
      `${this.masterDataPath}GetClaimStatusSelection`,
    );
  }

  getClaimFilterStatusSelection() {
    return this.http.get(`${this.masterDataPath}GetClaimFilterStatusSelection`);
  }

  updateFileStatus(id: string, data: any) {
    return this.http.post(`${this.basePath}UpdateFileStatus/${id}`, data);
  }

  uploadFile(docId: string, files: any[]) {
    let fd = new FormData();
    for (let i = 0; i < files.length; i++) {
      fd.append(`Files`, files[i]);
    }
    return this.http.post(`${this.basePath}UploadFile/${docId}`, fd);
  }

  updateIsAccepted(id: string) {
    return this.http.get(`${this.basePath}UpdateIsAccepted/${id}`);
  }
}
