<mat-toolbar class="sidenav-header">
  <div class="menu-container">
    <div class="menu-title">Upload Template</div>

    <button mat-icon-button class="menu-header-btn" (click)="close($event)">
      <mat-icon>closed</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>

<form class="form-field" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="upload-container">
    <div class="value-f">Upload File</div>
    <button mat-icon-button (click)="fileInputCL.click()" type="button">
      <img src="assets/icons/upload-icon.png" alt="" />
    </button>
    <input
      type="file"
      name="uploadedFile"
      #Image
      accept=".xls,.xlsx"
      style="display: none"
      #fileInputCL
      (change)="fileChangeEvent($event)"
    />
  </div>
  <div *ngIf="formGroup.value.uploadedFile" class="extra-upload-container">
    <span class="underlined-blue">
      {{ formGroup.value.uploadedFile.name }}
    </span>
    <button mat-icon-button (click)="deleteFile()" type="button">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <button
    style="margin-top: 24px"
    [disabled]="!formGroup.valid"
    mat-raised-button
    color="primary"
    class="full-width"
    type="submit"
  >
    Upload
  </button>
</form>
