import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class MotorDocumentDataService {
	private basePath: string = `${environment.url}/api/Motor/MotorDocument/`;

	constructor(private http: HttpClient) {}

	downloadById(id: string) {
		return this.http.get(`${this.basePath}DownloadById/${id}`, {
			responseType: "blob",
		});
	}
	
	downloadKTMBById(id: string) {
		return this.http.get(`${this.basePath}DownloadKTMBById/${id}`, {
			responseType: "blob",
		});
	}

	deleteById(id: string) {
		return this.http.get(`${this.basePath}DeleteById/${id}`);
	}
}
