import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FunctionPermissionsService {
  private userPermissions: string[] = [];

  constructor() {
    this.loadPermissions();
  }

  private loadPermissions() {
    this.userPermissions = localStorage
      .getItem("getTokenFunctionClaims")
      .split(",");
  }

  hasPermission(functionCodes: string | string[]): boolean {
    if (this.bypassPermissionCheck(functionCodes)) {
      return true;
    }

    if (Array.isArray(functionCodes)) {
      // Check if at least one function code is included in userPermissions
      return functionCodes.some((code) =>
        this.userPermissions.includes(code.toLowerCase()),
      );
    } else {
      // Check if the single function code is included in userPermissions
      return this.userPermissions.includes(functionCodes.toLowerCase());
    }
  }

  /**
   * Temporarily bypasses ACL (Access Control List) checks for specific function codes.
   *
   * @temporary This function is a workaround for access token size limitations.
   * @TODO Remove this function once the token size issue is resolved.
   */
  private bypassPermissionCheck(functionCodes: string | string[]): boolean {
    const bypassFunctionCodes = [
      "f_claims_riderprotectionsx",
      "f_riderprotection_sx",
      "f_damageprotection_maxis_exportdata",
      "f_damageprotection_maxis_cancelpolicy",
      "f_motor_bannerupload_newbanner"
    ];

    const hasBypassCode = (code: string) =>
      bypassFunctionCodes.some((bypassCode) => (code.toLowerCase()).includes(bypassCode));

    return Array.isArray(functionCodes)
      ? functionCodes.some(hasBypassCode)
      : hasBypassCode(functionCodes.toLowerCase());
  }
}
