import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectLendingRoutingModule } from './personal-protection-routing.module';
import { DirectLendingComponent } from './direct-lending/direct-lending.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DashboardRoutingModule } from '../dashboard/dashboard-routing.module';
import { MaterialModule } from '../material-module';
import { RiderAccidentRoutingModule } from '../rider-accident/rider-accident-routing.module';
import { EmptyRecordModule } from '../share/component/empty-record/empty-record.module';
import { SharedComponentModule } from '../share/component/shared-component.module';
import { CustomDirectiveModule } from '../share/directive/custom-directive.module';
import { UploadFileComponent } from './components/upload-file/upload-file.component';


@NgModule({
  declarations: [
    DirectLendingComponent,
    UploadFileComponent
  ],
  imports: [
    CommonModule,
    DirectLendingRoutingModule,
    RiderAccidentRoutingModule,
    MaterialModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    EmptyRecordModule,
    NgxSkeletonLoaderModule,
    CustomDirectiveModule,
    SharedComponentModule,
  ]
})
export class DirectLendingModule { }
