export function mapPartnerCode(partner: string) {
  switch (partner) {
    case "shopeefood":
      return "SF";

    case "foodpanda":
      return "FP";

    case "fp":
      return "FP";

    case "sf":
      return "SF";

    case "spxexpress":
      return "SX";

    default:
      return "FP";
  }
}
