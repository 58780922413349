import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { MotorClaimDataService } from "src/app/share/data-service/motor/motor-claim.data.service";
import { MotorInsuredPolicyDataService } from "src/app/share/data-service/motor/motor-insured-policy.data.service";
import { MotorPartnerDataService } from "src/app/share/data-service/motor/motor-partner.data.service";
import { MotorQuotationDataService } from "src/app/share/data-service/motor/motor-quotation.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import { RiderAccidentPartners } from "../_interfaces/rider-accidents.interface";
import { DeclarationHistoryListComponent } from "../components/declaration-history-list/declaration-history-list.component";
import { ImportDeclarationListDialogComponent } from "../components/import-declaration-list-dialog/import-declaration-list-dialog.component";
import { triggerFileDownload } from "src/app/share/helpers/downloadFile.helper";
import { mapPartnerCode } from "../_utils/rider-accident.utils";

@Component({
  selector: "app-rider-accidents",
  templateUrl: "./rider-accidents.component.html",
  styleUrls: ["./rider-accidents.component.scss"],
})
export class RiderAccidentsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "date",
    "name",
    "ic_no",
    "no_of_claim",
    "status",
    "action",
    "anchor",
  ];

  selectedStatus: string = "All";

  startDate: Date;
  endDate: Date;

  minFilterEndDate: Date;

  filterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";
  lastFilterSelected: "today" | "week" | "month" | "year" | "all" | "custom" =
    "all";

  searchText: string;
  searchChange: Subject<void> = new Subject<void>();
  unsubscribe$ = new Subject<void>();

  sortIndex: number = 0;
  sortDirection: "asc" | "desc" = "asc";

  status: string = null;
  limit: number = 50;
  offset: number = 1;

  // Partner data
  partner: string;
  private partnerDataList: RiderAccidentPartners[] = [];

  list: any[] = [];
  length = 0;

  isLoading: boolean = true;

  yesterdayCount: number = 0;
  isYesterdayCountLoading: boolean = true;
  before6Count: number = 0;
  after6Count: number = 0;
  selected: any;
  fileToUpload: any;

  @ViewChild("paginator") paginator;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private motorQuotationDataService: MotorQuotationDataService,
    private motorClaimDataService: MotorClaimDataService,
    private motorPartnerDataService: MotorPartnerDataService,
    private motorInsuredPolicyDataService: MotorInsuredPolicyDataService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getYesterdayCount();
    this.searchChange
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.filter();
      });

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.partner = params["partner"];
      this.resetAllFilters();
      this.getPartnerIdAndData();
    });
  }

  get partnerCode() {
    return mapPartnerCode(this.partner);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPartnerIdAndData() {
    this.motorPartnerDataService
      .getRiderAccidentPartners(this.partner)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (partnerDataList) => {
          this.partnerDataList = partnerDataList;
          this.getData();
        },
        error: (err) => {
          this.alertService.openSnackBar("Partner ID not found");
        },
      });
  }

  private getData() {
    this.isLoading = true;
    this.list = [];
    this.length = 0;
    this.motorInsuredPolicyDataService
      .getListing(
        this.partnerDataList[0].id,
        this.limit,
        this.offset,
        this.searchText,
        this.sortIndex,
        this.sortDirection,
        this.status,
        this.startDate,
        this.endDate,
        this.partner,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (x) => {
          this.isLoading = false;
          this.list = x.data;
          this.length = x.count;
          this.before6Count = x.before6Count;
          this.after6Count = x.after6Count;
        },
        error: (err) => {
          this.isLoading = false;
          this.alertService.openSnackBar(err.error);
        },
      });
  }

  importNewDeclarationList() {
    let dialogRef = this.dialog.open(ImportDeclarationListDialogComponent, {
      data: {
        partnerDataList: this.partnerDataList,
        partner: this.partner,
      },
    });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.filter();
      }
    });
  }

  downloadExcelTemplate() {
    const fileName = `${this.partner}-rider-declaration`;
    this.motorClaimDataService.downloadExcelTemplate().subscribe(
      (blob) => {
        triggerFileDownload(blob, `${fileName}.xlsx`);
      },
      (err) => {
        this.alertService.openSnackBar(`Error: ${err.message}`);
      },
    );
  }

  private getYesterdayCount() {
    this.motorQuotationDataService
      .getYesterdayCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (x) => {
          this.isYesterdayCountLoading = false;
          this.yesterdayCount = x;
        },
        error: (err) => {
          this.isYesterdayCountLoading = false;
          this.alertService.openSnackBar(err.error);
        },
      });
  }

  private setDefaultTimeRange() {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 999);
  }

  resetStatus() {
    this.selectedStatus = "All";
    this.status = null;
    this.filter();
  }

  filterStatus() {
    this.status = this.selectedStatus;
    this.getData();
  }

  //#region Date filter
  setTodayDate(hasFilter: boolean = true) {
    this.startDate = new Date();
    this.endDate = new Date();
    this.setDefaultTimeRange();
    if (hasFilter) {
      this.filter();
    }
  }

  setCurrentWeekDate() {
    this.startDate = moment().startOf("week").toDate();
    this.endDate = moment().endOf("week").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setCurrentYearDate() {
    this.startDate = moment().startOf("year").toDate();
    this.endDate = moment().endOf("year").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  resetDate() {
    this.startDate = null;
    this.endDate = null;
    this.filter();
  }

  onStartDateSelected(e) {
    this.startDate = e.target.value;
  }

  onEndDateSelected(e) {
    this.endDate = new Date(e.target.value.setHours(23, 59, 59, 999));
  }

  onDateRangePickerClosed() {
    if (this.startDate && this.endDate) {
      this.filter();
    } else if (this.startDate == null) {
      this.startDateClose();
    } else if (this.endDate == null) {
      this.endDateClose();
    }
  }

  startDateOpen() {
    this.startDate = null;
    this.endDate = null;
  }

  startDateClose() {
    if (this.startDate == null) {
      this.filterSelected = this.lastFilterSelected;
      switch (this.filterSelected) {
        case "today":
          this.setTodayDate();
          break;
        case "week":
          this.setCurrentWeekDate();
          break;
        case "month":
          this.setCurrentMonthDate();
          break;
        case "year":
          this.setCurrentYearDate();
          break;
        case "all":
          this.resetDate();
          break;
      }
    }
  }

  endDateClose() {
    if (this.endDate == null) {
      this.endDate = this.startDate;
    }
    this.filter();
  }

  resetAllFilters() {
    this.searchText = null;
    this.selectedStatus = "All";
    this.status = null;
    this.startDate = null;
    this.endDate = null;
    this.filterSelected = "all";
    this.lastFilterSelected = this.filterSelected;
  }

  resetDateFilter() {
    event.stopImmediatePropagation();
    this.resetDate();
    this.filterSelected = "all";
    this.lastFilterSelected = this.filterSelected;
  }
  //#endregion

  resetTextFilter() {
    event.stopImmediatePropagation();
    this.searchText = null;
    this.filter();
  }

  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active,
      );
    }
    if (this.list.length == 0) return;

    this.getPartnerIdAndData();
  }
  pageChange(e: PageEvent) {
    this.offset = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.getPartnerIdAndData();
  }
  filter() {
    this.offset = 1;
    this.getPartnerIdAndData();
  }

  searchChanged() {
    this.searchChange.next();
  }

  viewDeclarationHistory(data: any): void {
    let dialogRef = this.dialog.open(DeclarationHistoryListComponent, {
      data: {
        id: data.motorInsuredId,
        identityNo: data.identityNo,
        startDate: data.startDate,
        endDate: data.endDate,
        name: data.name,
      },
    });
    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.filter();
      }
    });
  }
}
