import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { VehicleInterface } from "../../interface/common.interface";
import {
  MotorQuotationChangeOwnershipDetailInterface,
  MotorQuotationDetailInterface,
  MotorQuotationLinkListingInterface,
  MotorQuotationListingInterface,
  MotorQuotationNCDInfo,
  MotorQuotationPolicyDetailListingInterface,
  MotorQuotationPolicyInterface,
  MotorQuotationPolicyListingInterface,
  MotorQuotationUpdateNcd,
  MotorQuotationVehicleInfo
} from "../../interface/motor/motor-quotation.interface";

@Injectable({
  providedIn: "root"
})
export class MotorQuotationDataService {
  private basePath: string = `${environment.url}/api/Motor/MotorQuotation/`;

  constructor(private http: HttpClient) {}

  getYesterdayCount() {
    return this.http.get<number>(`${this.basePath}GetYesterdayCount`);
  }

  getListing(request: MotorQuotationRequest) {
    return this.http.post<MotorQuotationResponse>(
      `${this.basePath}GetListing`,
      request,
    );
  }

  getIQQuotationListing(request: MotorQuotationRequest) {
    return this.http.post<MotorQuotationResponse>(
      `${this.basePath}GetIQQuotationListing`,
      request,
    );
  }

  getDetailById(id: string) {
    return this.http.get<MotorQuotationDetailInterface>(
      `${this.basePath}GetDetailById/${id}`
    );
  }

  getChangeOwnershipDetail(id: string) {
    return this.http.get<MotorQuotationChangeOwnershipDetailInterface>(
      `${this.basePath}GetChangeOwnershipDetail/${id}`
    );
  }

  reject(
    id: string,
    rejectReasonId: string,
    rejectRemark: string,
    comment: string,
    isInstantQuotation?: boolean
  ) {
    //Note:
    //Reject Type Id (from FE) is Reject Reason Id (BE)
    //Reject Reason Id (from FE) is Reject Remark (BE)
    //Reject Remark (from FE) is Reject comment (BE)
    //isInstantQuotation (from FE) is indicate it's for instant quotation (BE)
    return this.http.post(`${this.basePath}Reject/${id}`, {
      rejectReasonId,
      rejectRemark,
      comment,
      isInstantQuotation
    });
  }

  getQuotationPolicyById(id: string) {
    return this.http.get<MotorQuotationPolicyInterface>(
      `${this.basePath}GetQuotationPolicyById/${id}`
    );
  }

  getCarInfo(vehicleNo: string, identityNo: string) {
    return this.http.post<{
      vehicleInfo: MotorQuotationVehicleInfo;
      seat: number;
      typeOfVehicle: string;
      ncdInfo: MotorQuotationNCDInfo;
    }>(`${this.basePath}GetVehicleInfo`, {
      vehicleNo,
      identityNo
    });
  }

  editVehicle(id: string, val: any) {
    return this.http.post(`${this.basePath}EditVehicle/${id}`, val);
  }

  updateQuotationNcd(id: string, val: MotorQuotationUpdateNcd) {
    return this.http.post(`${this.basePath}UpdateQuotationNCD/${id}`, val);
  }

  editCustomer(id: string, val: any) {
    return this.http.post(`${this.basePath}EditCustomer/${id}`, val);
  }

  editNCD(id: string, previousVehicleNo: string, ncd: number) {
    return this.http.post(`${this.basePath}UpdateNCD/${id}`, {
      previousVehicleNo,
      ncd
    });
  }

  getVehicleInfo(vehicleNo: string, identityNo: string) {
    return this.http.post<VehicleInterface>(`${this.basePath}getVehicleInfo`, {
      vehicleNo,
      identityNo
    });
  }

  add(val: any) {
    return this.http.post(`${this.basePath}Add`, val);
  }

  approve(id: string, val: any) {
    return this.http.post(`${this.basePath}Approve/${id}`, val);
  }

  getPolicyListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    motorPartnerIds: string[],
    motorProductIds: string[],
    statusList: string[],
    isTransferOwnership: boolean,
    startDate?: Date,
    endDate?: Date
  ) {
    return this.http.post<{
      count: any;
      data: MotorQuotationPolicyListingInterface[];
    }>(`${this.basePath}GetPolicyListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      motorPartnerIds,
      motorProductIds,
      isTransferOwnership,
      statusList,
      startDate,
      endDate
    });
  }

  getPolicyListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    motorPartnerIds: string[],
    motorProductIds: string[],
    statusList: string[],
    isTransferOwnership: boolean,
    startDate?: Date,
    endDate?: Date
  ) {
    return this.http.post(
      `${this.basePath}GetPolicyListingExcel`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        motorPartnerIds,
        motorProductIds,
        statusList,
        isTransferOwnership,
        startDate,
        endDate
      },
      {
        responseType: "blob"
      }
    );
  }

  editRoadTax(id: string, price: number) {
    return this.http.get(`${this.basePath}EditRoadTax/${id}?price=${price}`);
  }

  getPolicyDetailListingById(id: string) {
    return this.http.get<MotorQuotationPolicyDetailListingInterface[]>(
      `${this.basePath}GetPolicyDetailListingByRequestId/${id}`
    );
  }

  getLink(id: string) {
    return this.http.get<MotorQuotationLinkListingInterface[]>(
      `${this.basePath}getLink/${id}`
    );
  }

  refreshLink(id: string) {
    return this.http.get(`${this.basePath}RefreshLink/${id}`);
  }

  getClonedQuotationValue(id: string) {
    return this.http.get(`${this.basePath}GetClonedQuotationValue/${id}`);
  }

  SendQuotationEmail(id: string) {
    return this.http.get(`${this.basePath}SendQuotationEmail/${id}`);
  }

  getPAndOVehicleInfo(id: string) {
    return this.http.post(`${this.basePath}GetPAndOVehicleInfo/${id}`, null);
  }

  getBulkQuotationListing(request: MotorQuotationRequest) {
    return this.http.post<{
      count: any;
      data: any[];
    }>(`${this.basePath}GetBulkQuotationListing`, request);
  }

  uploadBulkQuotationFile(id: string, file: any) {
    let fd = new FormData();

    fd.append(`File`, file);
    fd.append(`PartnerId`, id);
    return this.http.post(`${this.basePath}UploadBulkQuotationExcel`, fd, {
      responseType: "blob"
    });
  }

  downloadTemplate() {
    return this.http.get(
      `${this.basePath}DownloadPolicyBulkCancelExcelTemplate`,
      {
        responseType: "blob"
      }
    );
  }

  getInstantQuotation(id: string) {
    return this.http.get(`${this.basePath}GetD2CInstantQuotationById/${id}`);
  }

  onHoldQuotation(id: string) {
    return this.http.post<any>(`${this.basePath}OnHoldQuotation/${id}`, null);
  }
}

export interface MotorQuotationRequest {
  limit: number;
  offset: number;
  text: string;
  columnIndex: number;
  sortDirection: string;
  motorPartnerIds: string[];
  motorProductIds: string[];
  isTransferOwnership: boolean;
  status?: string;
  statusList?: string[];
  startDate?: Date;
  endDate?: Date;
}

interface MotorQuotationResponse {
  count: number;
  data: MotorQuotationListingInterface[];
  before6Count: number;
  after6Count: number;
}
