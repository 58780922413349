<div>
  <div mat-dialog-title>Import New Rider Declaration List</div>
  <mat-divider></mat-divider>
  <div>
    <div mat-dialog-content>
      <div class="mb-lg">
        <div>
          Please upload the latest Declaration List Excel file provided by
          customer.
        </div>
        <div>
          * Declaration period will be 7 days by default from the start date.
        </div>
      </div>
      <form [formGroup]="formGroup">
        <mat-form-field class="full-width">
          <mat-label>Effective start date</mat-label>
          <input
            matInput
            autocomplete="off"
            (click)="declarationStartDate.open()"
            [matDatepicker]="declarationStartDate"
            (dateChange)="getNextDeclarationPeriod($event)"
            readonly
            formControlName="startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="declarationStartDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #declarationStartDate></mat-datepicker>
          <mat-error>Declaration start date is required</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Effective end date</mat-label>
          <input
            matInput
            autocomplete="off"
            (click)="declarationEndDate.open()"
            [value]="calculatedEndDate"
            [matDatepicker]="declarationEndDate"
            [matDatepickerFilter]="endDateFilter"
            readonly
            formControlName="endDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="declarationEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #declarationEndDate></mat-datepicker>
          <mat-error>Declaration end date is required</mat-error>
        </mat-form-field>

        <input
          type="file"
          id="importData"
          style="display: none"
          (change)="handleFileInput($event)"
          [disabled]="formGroup.invalid"
        />
        <button mat-raised-button color="primary" class="m-b-16">
          <label for="importData" class="label-btn"> Upload file </label>
        </button>
      </form>
    </div>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Close</button>
    </div>
  </div>
</div>
