import { HostListener } from "@angular/core";
import { Directive, Input } from "@angular/core";

@Directive({
	selector: "[emailTo]",
})
export class EmailToDirective {
	@Input("emailTo") email: string;

	@HostListener("click")
	onClick() {
		location.href = `mailto:${this.email}`;
	}
}
