import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MotorClaimDataService } from "src/app/share/data-service/motor/motor-claim.data.service";
import { AlertService } from "src/app/share/service/alert.service";
import * as moment from "moment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RiderAccidentPartners } from "../../_interfaces/rider-accidents.interface";

@Component({
  selector: "app-import-declaration-list-dialog",
  templateUrl: "./import-declaration-list-dialog.component.html",
  styleUrls: ["./import-declaration-list-dialog.component.scss"],
})
export class ImportDeclarationListDialogComponent {
  fileToUpload: any;
  formGroup: FormGroup;
  calculatedEndDate: any;
  endDateFilter = (d: Date | null): boolean => {
    return d > this.formGroup.value.startDate;
  };

  constructor(
    private motorClaimDataService: MotorClaimDataService,
    public dialogRef: MatDialogRef<ImportDeclarationListDialogComponent>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      partnerDataList: RiderAccidentPartners[];
      partner?: string;
    },
  ) {
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({
      startDate: new FormControl(new Date(), [Validators.required]),
      endDate: new FormControl(moment(new Date()).add(7, "days").toDate(), [
        Validators.required,
      ]),
    });
  }

  getNextDeclarationPeriod(startDate: any) {
    const effectiveEndDate = moment(startDate.value).add(7, "days").toDate();
    this.calculatedEndDate = effectiveEndDate;
    this.formGroup.controls["endDate"].setValue(effectiveEndDate);
  }

  handleFileInput(files) {
    this.fileToUpload = files.target.files[0];
    files.target.value = null;
    const startDate = this.formGroup.controls["startDate"].value;
    const endDate = this.formGroup.controls["endDate"].value;

    if (this.fileToUpload) {
      this.motorClaimDataService
        .uploadInsuredExcel(
          this.data.partnerDataList,
          this.fileToUpload,
          startDate,
          endDate,
          this.data.partner,
        )
        .subscribe({
          next: (x) => {
            if (x.size != 0) {
              let dataType = x.type;
              let binaryData = [];
              binaryData.push(x);
              let downloadLink = document.createElement("a");
              downloadLink.href = window.URL.createObjectURL(
                new Blob(binaryData, { type: dataType }),
              );
              downloadLink.setAttribute("download", "error");

              document.body.appendChild(downloadLink);
              downloadLink.click();
            } else {
              this.alertService.openSnackBar("File uploaded successfully");
            }
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.alertService.openSnackBar("Error: can't upload file");
          },
        });
    }
  }
}
