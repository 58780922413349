import { Component, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MotorDocumentDataService } from "src/app/share/data-service/motor/motor-document.data.service";
import { MotorInsuredPolicyDataService } from "src/app/share/data-service/motor/motor-insured-policy.data.service";
import {
  ConfirmationDialog,
  ConfirmationDialogModel,
} from "src/app/share/dialog/confirmation-dialog/confirmation.dialog";
import {
  ClaimDetailsInterface,
  CoverageInterface,
  PolicyInterface,
} from "src/app/share/interface/claims/claims.interface";
import {
  RiderAccidentDetailsInterface,
  RiderInfoInterface,
} from "src/app/share/interface/claims/riders.interface";
import { MotorDocumentInterface } from "src/app/share/interface/common.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { mapPartnerCode } from "../_utils/rider-accident.utils";
declare var $: any;

@Component({
  selector: "app-rider-accident-details",
  templateUrl: "./rider-accident-details.component.html",
  styleUrls: ["./rider-accident-details.component.scss"],
})
export class RiderAccidentDetailsComponent implements OnInit {
  id: string;
  riderData: RiderInfoInterface;
  headerOffset: number = 70;
  selectedElement: string = "";
  insuranceSearchText: string;
  claimsSearchText: string;
  isLoading: boolean;
  policyData: PolicyInterface;
  coverageList: CoverageInterface[];
  claimList: ClaimDetailsInterface[];
  documentList: MotorDocumentInterface[];
  claimsDisplayedColumns: string[] = [
    "date",
    "referenceNo",
    "insuranceType",
    "status",
    "action",
  ];

  partner: string;
  searchClaimsChange: Subject<void> = new Subject<void>();
  claimPaginationList: ClaimDetailsInterface[];
  claimStatus: string = "All";

  limit: number = 10;
  offset: number = 0;
  length: number;

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private motorDocumentDataService: MotorDocumentDataService,
    private motorInsuredPolicyDataService: MotorInsuredPolicyDataService,
  ) {
    if (!route.snapshot.queryParams.id) window.history.back();
    else {
      this.id = route.snapshot.queryParams.id;
      this.searchClaimsChange.pipe(debounceTime(500)).subscribe(() => {
        this.filterClaimHistory();
      });
      this.getData();
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.partner = params["partner"];
    });
  }

  get partnerCode() {
    return mapPartnerCode(this.partner);
  }

  private getData() {
    this.motorInsuredPolicyDataService.getDetailById(this.id).subscribe({
      next: (x: RiderAccidentDetailsInterface) => {
        this.policyData = x.policy;
        this.coverageList = x.insuranceCoverage;
        this.riderData = x.userInfo;
      },
      error: (err) => {
        console.log(err);
      },
    });

    this.motorInsuredPolicyDataService
      .getPolicyClaims(this.id, this.claimsSearchText)
      .subscribe({
        next: (x: ClaimDetailsInterface[]) => {
          if (x) {
            this.claimList = x;
            this.filterClaimHistory();
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  viewClaim(element: ClaimDetailsInterface) {
    this.router.navigate([`../claims/rider/${this.partner}/claim-details`], {
      queryParams: { id: element.id },
    });
  }

  getDocuments() {
    this.motorInsuredPolicyDataService
      .getDocuments(this.policyData.id)
      .subscribe({
        next: (x: MotorDocumentInterface[]) => {
          this.documentList = x;
        },
        error: (err) => {},
      });
  }

  download(val: MotorDocumentInterface) {
    this.ngxSpinnerService.show();
    this.motorDocumentDataService.downloadById(val.id).subscribe({
      next: (x) => {
        this.ngxSpinnerService.hide();
        let dataType = val.contentType;
        let binaryData = [];
        binaryData.push(x);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType }),
        );
        downloadLink.setAttribute("download", val.fileName);

        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      error: (err) => {
        err.error.text().then((x) => {
          this.alertService.openSnackBar(x);
        });
        this.ngxSpinnerService.hide();
      },
    });
  }

  togglePolicy() {
    if (this.policyData.status != "Active") {
      this.motorInsuredPolicyDataService
        .updateStatusPolicy(this.policyData.id)
        .subscribe({
          next: (x) => {
            this.getData();
            this.alertService.openSnackBar(
              `Policy ${this.policyData.insuranceNo} is activated`,
            );
          },
          error: (err) => {
            this.alertService.openSnackBar(
              `Error: Unable to activate rider policy`,
            );
          },
        });
    } else {
      let data: ConfirmationDialogModel = {
        title: "Deactivate Policy",
        content: "Are you sure want to deactivate policy: ",
        btnColor: "warn",
        okBtnText: "Deactivate",
        boldContent: this.policyData.insuranceNo,
      };
      let dialogRef = this.dialog.open(ConfirmationDialog, { data: data });
      dialogRef.afterClosed().subscribe((x) => {
        if (x) {
          this.motorInsuredPolicyDataService
            .updateStatusPolicy(this.policyData.id)
            .subscribe({
              next: (x) => {
                this.getData();
                this.alertService.openSnackBar(
                  `Policy ${this.policyData.insuranceNo} is deactivated`,
                );
              },
              error: (err) => {
                this.alertService.openSnackBar(
                  `Error: Unable to deactivate rider policy`,
                );
              },
            });
        }
      });
    }
  }

  getDocumentName(refType: string) {
    switch (refType) {
      case "PartnerCovernote":
        return "Covernote";
      case "PartnerPDS":
        return "Product Disclosure Sheet";
      case "PartnerPW":
        return "Policy Wording";
      default:
        return "";
    }
  }

  convertCoverageSummaryValue(value: string, key: string) {
    if (key == "hospitalIncome") {
      return value + " per day";
    }

    return value;
  }

  searchClaimsHistoryChanged() {
    this.searchClaimsChange.next();
  }

  pageChange(e: PageEvent) {
    this.offset = e.pageIndex;
    this.limit = e.pageSize;
    this.filterClaimHistory();
  }

  setPagination() {
    const from = this.offset * this.limit;
    const to = from + this.limit;
    this.claimPaginationList = this.claimPaginationList.slice(from, to);
  }

  resetClaimsSearchFilter() {
    this.claimsSearchText = "";
    this.filterClaimHistory();
  }

  filterClaimHistory() {
    let tempList = this.claimList;

    if (this.claimStatus !== "All" || this.claimsSearchText) {
      if (this.claimStatus !== "All") {
        tempList = this.claimList.filter((x) => x.status == this.claimStatus);
      }

      if (this.claimsSearchText) {
        tempList = tempList.filter((x) =>
          x.refNo.includes(this.claimsSearchText.toUpperCase()),
        );
      }
      this.offset = 0;
    }
    this.claimPaginationList = tempList;
    this.length = tempList.length;
    this.setPagination();
  }
}
