<mat-toolbar class="page-header">
  <div class="menu-container">
    <div class="title-container">
      <div class="bg-grey info">
        <mat-icon>info</mat-icon>
      </div>
      <div class="pg-title">
        {{ partner | titlecase }}
        <div class="label-f">Rider Protection</div>
      </div>
    </div>

    <div class="filter-container">
      <mat-form-field appearance="outline" floatLabel="never" class="pg-filter">
        <input
          matInput
          placeholder="Search here..."
          autocomplete="off"
          [(ngModel)]="searchText"
          (ngModelChange)="searchChanged()"
        />
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          (click)="resetTextFilter()"
          *ngIf="searchText"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="never" class="pg-filter">
        <mat-select [(ngModel)]="selectedStatus">
          <mat-option value="All" (click)="resetStatus()"> All </mat-option>

          <mat-option value="Active" (click)="filterStatus()">
            Active
          </mat-option>

          <mat-option value="Inactive" (click)="filterStatus()">
            Deactivated
          </mat-option>

          <mat-option value="Expired" (click)="filterStatus()">
            Expired
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="pg-filter"
        (click)="lastFilterSelected = filterSelected"
      >
        <mat-select [(ngModel)]="filterSelected">
          <mat-select-trigger>
            <ng-container [ngSwitch]="filterSelected">
              <span *ngSwitchCase="'today'">Today</span>
              <span *ngSwitchCase="'week'">This Week</span>
              <span *ngSwitchCase="'month'">This Month</span>
              <span *ngSwitchCase="'year'">This Year</span>
              <span *ngSwitchCase="'all'">All Time</span>
              <span *ngSwitchDefault>
                <span *ngIf="endDate">
                  {{ startDate | date : "dd/MM/yy" }}
                  - {{ endDate | date : "dd/MM/yy" }}
                </span>
              </span>
            </ng-container>
          </mat-select-trigger>
          <mat-option value="all" (click)="resetDate()">All Time</mat-option>
          <mat-option value="year" (click)="setCurrentYearDate()"
            >This Year</mat-option
          >
          <mat-option value="month" (click)="setCurrentMonthDate()"
            >This Month</mat-option
          >
          <mat-option value="week" (click)="setCurrentWeekDate()"
            >This Week</mat-option
          >
          <mat-option value="today" (click)="setTodayDate()">Today</mat-option>
          <mat-option value="custom" (click)="picker.open()"
            >Custom Range</mat-option
          >
          <!-- NEW DATE PICKER UI  -->
          <mat-date-range-input style="display: none" [rangePicker]="picker">
            <input
              matStartDate
              [(ngModel)]="startDate"
              (dateChange)="onStartDateSelected($event)"
              placeholder="Start date"
            />
            <input
              matEndDate
              [(ngModel)]="endDate"
              (dateChange)="onEndDateSelected($event)"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-date-range-picker
            #picker
            (opened)="startDateOpen()"
            (closed)="onDateRangePickerClosed()"
          >
          </mat-date-range-picker>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          class="clear-field-btn"
          (click)="resetDateFilter()"
          *ngIf="filterSelected !== 'all'"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</mat-toolbar>

<div class="content-container">
  <div class="flex justify-between">
    <button
      appCheckFunctionPermission
      [functionCode]="
        'f_riderprotection_' + partnerCode + '_importdeclarationlist'
      "
      mat-raised-button
      color="primary"
      (click)="importNewDeclarationList()"
    >
      Import Declaration List
    </button>
    <button mat-stroked-button (click)="downloadExcelTemplate()">
      Download Template
    </button>
  </div>

  <mat-card class="card-table">
    <table
      mat-table
      [dataSource]="list"
      matSort
      (matSortChange)="sortChange($event)"
      matSortActive="date"
      matSortDisableClear
      matSortDirection="asc"
    >
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Latest Declaration
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate | date : "dd MMM yyyy" }} -
          {{ element.endDate | date : "dd MMM yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="ic_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IC No.</th>
        <td mat-cell *matCellDef="let element">{{ element.identityNo }}</td>
      </ng-container>

      <ng-container matColumnDef="no_of_claim">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No. of Claim</th>
        <td mat-cell *matCellDef="let element">{{ element.claimNo }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>

        <td mat-cell *matCellDef="let element" [ngSwitch]="element.status">
          <span class="cover-label blue" *ngSwitchCase="'Active'">
            Active
          </span>
          <span class="cover-label grey" *ngSwitchCase="'Inactive'">
            Deactivated
          </span>
          <span class="cover-label red" *ngSwitchCase="'Expired'">
            Expired
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="selected = element; $event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Anchor Column -->
      <ng-container matColumnDef="anchor">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a
            [href]="
              '/rideraccident/' +
              partner +
              '/rider-accident-details?id=' +
              element.id
            "
          ></a>
        </mat-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          <app-empty-record
            *ngIf="!isLoading && list.length === 0"
            [disableSubTitle]="true"
          ></app-empty-record>
          <app-skeleton-loader *ngIf="isLoading"> </app-skeleton-loader>
        </td>
      </tr>
    </table>

    <mat-divider></mat-divider>

    <mat-paginator
      #paginator
      [pageSizeOptions]="[50, 100, 150]"
      [pageSize]="limit"
      [length]="length"
      (page)="pageChange($event)"
      aria-label="Select page of users"
    ></mat-paginator>
  </mat-card>
</div>
<mat-menu #menu="matMenu">
  <div
    appCheckFunctionPermission
    [functionCode]="
      'f_riderprotection_' + partnerCode + '_viewdeclarationhistory'
    "
  >
    <button
      appCheckFunctionPermission
      [functionCode]="
        'f_riderprotection_' + partnerCode + '_viewdeclarationhistory'
      "
      mat-menu-item
      (click)="viewDeclarationHistory(selected)"
    >
      View declaration history
    </button>
  </div>
</mat-menu>
