import { IMatSelectOption } from "../../interface/common.interface";

export enum ClaimStatus {
  New = "N",
  Processing = "P",
  OpsRejected = "OR",
  PendingInsurerAcceptance = "PIA",
  PendingInsurerEvaluation = "PIE",
  InsurerApproved = "IA",
  InsurerRejected = "IR",
  InsurerWithdrawn = "IW",
  PendingTPAAssessment = "PTA",
  PendingRepairReplace = "PRR",
  Completed = "C"
}

export const CLAIM_STATUS_LABEL_MAP = {
  [ClaimStatus.New]: "New",
  [ClaimStatus.Processing]: "Processing",
  [ClaimStatus.OpsRejected]: "Ops Rejected",
  [ClaimStatus.PendingInsurerAcceptance]: "Pending Insurer Acceptance",
  [ClaimStatus.PendingInsurerEvaluation]: "Pending Insurer Evaluation",
  [ClaimStatus.InsurerApproved]: "Insurer Approved",
  [ClaimStatus.InsurerRejected]: "Insurer Rejected",
  [ClaimStatus.InsurerWithdrawn]: "Insurer Withdrawn",
  [ClaimStatus.PendingTPAAssessment]: "Pending TPA Assessment",
  [ClaimStatus.PendingRepairReplace]: "Pending Repair Replace",
  [ClaimStatus.Completed]: "Completed"
};

export function getClaimStatusLabel(claimStatus: string): string {
  return CLAIM_STATUS_LABEL_MAP[claimStatus] || "";
}

export function getClaimStatusList(): string[] {
  return Object.keys(CLAIM_STATUS_LABEL_MAP);
}

/**
 * Ops can only update claim status IN ('New', 'Processing')
 * NEW -> PROCESSING
 * PROCESSING -> OPS_REJECTED || PENDING_INSURER_ACCEPTANCE
 * */
export function getClaimStatusOptions(
  claimStatus: ClaimStatus
): IMatSelectOption[] {
  const claimStatusMap = {
    [ClaimStatus.New]: [
      {
        id: ClaimStatus.Processing,
        label: CLAIM_STATUS_LABEL_MAP[ClaimStatus.Processing]
      }
    ],
    [ClaimStatus.Processing]: [
      {
        id: ClaimStatus.OpsRejected,
        label: CLAIM_STATUS_LABEL_MAP[ClaimStatus.OpsRejected]
      },
      {
        id: ClaimStatus.PendingInsurerAcceptance,
        label: CLAIM_STATUS_LABEL_MAP[ClaimStatus.PendingInsurerAcceptance]
      }
    ]
  };

  return claimStatusMap[claimStatus] || [];
}
